<template>
	<div v-loading="allLoading">
		<header class="header">
			<i class="title-icon iconfont icon-shu"></i>
			<h5 style="font-size: 16px;">试卷库</h5>
		</header>
		<div class="gg-container" style="height: 100%;">
			<div class="flex justify-center">
				<el-steps :active="active" align-center>
					<el-step title="基础信息"></el-step>
					<el-step title="选择题目"></el-step>
					<el-step title="完成"></el-step>
				</el-steps>
			</div>
			<div class="" v-if="active==1">
				<el-form :model="testPaper" :rules="rules" :show-message="false" ref="ruleForm">
					<el-form-item prop="title">
						<div class="select flex justify-center mt-2">
							<label class="require">问卷名称：</label>
							<el-input size="small" placeholder="请输入问卷名称" clearable style="width: 50%;margin-left: 20px;"
								v-model="testPaper.title">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item prop="desc">
						<div class="select flex justify-center mt-2">
							<label class="require">问卷简介：</label>
							<el-input size="small" placeholder="请输入问卷简介" clearable style="width: 50%;margin-left: 20px;"
								v-model="testPaper.desc">
							</el-input>
						</div>
					</el-form-item>
					<el-form-item prop="agent_id" v-if="is_admin=='1'">
						<div class="select flex justify-center mt-2">
							<label class="require">选择机构：</label>
							<el-select v-model="testPaper.agent_id" clearable placeholder="请选择机构" size="small"
								style="width: 50%;margin-left: 20px;">
								<el-option v-for="(item,index) in orderList" :key="index" :label="item.merchant_name"
									:value="item.id">
								</el-option>
							</el-select>
						</div>
					</el-form-item>
					<el-form-item prop="depart_id">
						<div class="select flex justify-center mt-2">
							<label class="require">选择科室：</label>
							<el-cascader style="width: 50%;margin-left: 20px;" v-model="testPaper.depart_id"
								:options="departDic" :props="{ checkStrictly: true }" clearable></el-cascader>
						</div>

					</el-form-item>
				</el-form>
			</div>
			<div class="" v-if="active==2">
				<div class="step2 flex justify-center" v-if="!viewQuestionList.length">
					<div class="step2-bacc" style="margin-top: 100px;">
						<i class="icon-kaoshi-shitikuguanli-shijuanguanli iconfont"></i>
					</div>
				</div>
				<div class="" v-if="viewQuestionList.length">
					<div class="topics">
						<div class="" v-for="(item,index) in viewQuestionList" :key="index">
							<!-- 单选 -->
							<el-card class="box-card single-choice" v-if="item.type=='1'">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>
									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer flex">
									<div class="question-answer-item" v-for="(item1,index1) in item.options_value"
										:key="index1">
										<div class=""></div>
										<span>{{item1.name}}</span>
									</div>
								</div>
							</el-card>

							<!-- 填空 -->
							<el-card class="box-card gap-filling" v-if="item.type=='5'">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>
									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer">
									<span class="question-answer-gap">{{item.content}}</span>
								</div>
							</el-card>


							<!-- 多选 -->
							<el-card class="box-card" v-if="item.type=='2'">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>

									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer flex">
									<div class="question-answer-item" v-for="(item2,index2) in item.options_value"
										:key="index2">
										<div class="" style="border-radius: unset;"></div>
										<span>{{item2.name}}</span>
									</div>
								</div>
							</el-card>

							<!-- 图片 -->
							<el-card class="box-card gap-filling" v-if="item.type=='3'">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>

									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer flex align-center">
									<div class="question-answer-img">
										<div class=""><img src="../../assets/photo.png"
												style="width: 20px;height: 20px;">
										</div>
									</div>
									<div class=""><span
											style="color: #999999;">上传病历、检查报告或患处照片(最多上传{{item.img_count}}张)</span></div>
								</div>
							</el-card>

							<!-- 简答 -->
							<el-card class="box-card gap-filling" v-if="item.type==4">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>

									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer">
									<textarea class="textareaStyle" rows="" cols="" disabled="true"></textarea>
								</div>
							</el-card>


							<!-- 单选简答 -->
							<el-card class="box-card gap-filling" v-if="item.type==6">
								<div class="question-title">
									<el-checkbox style="color: #333; font-weight: bolder"
										@change="selectQuestions($event,item,index)" v-model="item.isOn">
										{{index+1}}. {{item.title}} (ID：{{item.id}})
									</el-checkbox>
									<el-tag effect="plain" size="mini" class="mgl-70"
										style="height: 19px;margin-left: 60px;">{{item.type_name}}
									</el-tag>
								</div>
								<div class="question-answer flex" style="margin-top: 10px;">
									<div class="question-answer-item " v-for="(item3,index3) in item.options_value"
										:key="index3">
										<div class=""></div>
										<span>{{item3.name}}</span>
									</div>
								</div>
								<div class="question-answer" style="margin-top: 10px;">
									<textarea class="textareaStyle" rows="" cols="" disabled="true"></textarea>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-center" style="margin-top: 50px;">
				<el-button type="info" size="mini" @click="previous" v-if="active==2" style="width: 80px;">上一步
				</el-button>
				<el-button type="primary" size="mini" v-if="active==2" style="width: 80px;" @click="addTopic">添加试题
				</el-button>
				<el-button type="danger" size="mini" v-if="active==2&&viewQuestionList.length" style="width: 80px;"
					@click="removeTopoc">移除试题
				</el-button>
				<el-button type="primary" size="mini" @click="next" v-if="active==1" style="width: 80px;">下一步
				</el-button>
				<el-button type="success" size="mini" @click="submit" v-if="active==2" style="width: 80px;">保存
				</el-button>
			</div>
			<el-dialog :visible.sync="dialogVisible" title="添加题目" v-loading="loading">
				<div class="flex">
					<el-select clearable placeholder="请选择题型" size="small" style="width: 30%;" v-model="type"
						@change="getQuestionsList(type)">
						<el-option v-for="item in topicList" :key="item.type" :label="item.name" :value="item.type">
						</el-option>
					</el-select>
					<el-input size="small" placeholder="请输入题干" clearable style="width: 30%;margin-left: 20px;"
						@change="getQuestionsList" v-model="keywords">
					</el-input>
				</div>

				<div class="topics">

					<!-- 单选 -->
					<div class="" v-for="(item,index) in questionList" :key="index">
						<el-card class="box-card single-choice" v-if="item.type=='1'">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer flex">
								<div class="question-answer-item" v-for="(item1,index1) in item.options_value"
									:key="index1">
									<div class=""></div>
									<span>{{item1.name}}</span>
								</div>
							</div>
						</el-card>


						<!-- 填空 -->
						<el-card class="box-card gap-filling" v-if="item.type=='5'">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer">
								<span class="question-answer-gap">{{item.content}}</span>
							</div>
						</el-card>


						<!-- 多选 -->
						<el-card class="box-card" v-if="item.type=='2'">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer flex">
								<div class="question-answer-item" v-for="(item2,index2) in item.options_value"
									:key="index2">
									<div class="" style="border-radius: unset;"></div>
									<span>{{item2.name}}</span>
								</div>
							</div>
						</el-card>

						<!-- 图片 -->
						<el-card class="box-card gap-filling" v-if="item.type=='3'">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer flex align-center">
								<div class="question-answer-img">
									<div class=""><img src="../../assets/photo.png" style="width: 20px;height: 20px;">
									</div>
								</div>
								<div class=""><span
										style="color: #999999;">上传病历、检查报告或患处照片(最多上传{{item.img_count}}张)</span></div>
							</div>
						</el-card>

						<!-- 简答 -->
						<el-card class="box-card gap-filling" v-if="item.type==4">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer">
								<textarea class="textareaStyle" rows="" cols="" disabled="true"></textarea>
							</div>
						</el-card>
						<!-- 单选简答 -->
						<el-card class="box-card gap-filling" v-if="item.type==6">
							<div class="question-title">
								<el-checkbox style="color: #333; font-weight: bolder"
									@change="selectQuestion($event,item,index)" v-model="item.isOn">
									{{index+1}}. {{item.title}} (ID：{{item.id}})
								</el-checkbox>
								<el-tag effect="plain" size="mini" class="mgl-70"
									style="height: 19px;margin-left: 60px;">{{item.type_name}}
								</el-tag>
							</div>
							<div class="question-answer flex" style="margin-top: 10px;">
								<div class="question-answer-item " v-for="(item3,index3) in item.options_value"
									:key="index3">
									<div class=""></div>
									<span>{{item3.name}}</span>
								</div>

							</div>
							<div class="question-answer" style="margin-top: 10px;">
								<textarea class="textareaStyle" rows="" cols="" disabled="true"></textarea>
							</div>
						</el-card>
					</div>

				</div>
				<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
					@pagination="getQuestionsList" />
				<div class="flex" style="justify-content: flex-end;margin-top: 20px;">
					<el-button size="mini" @click="close">取消</el-button>
					<el-button size="mini" type="primary" @click="save">保存</el-button>
				</div>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import {
		getTestQuestions,
		addTestPaper,
		getQuestionInfo,
		undataQuestion
	} from '@/api/cdm/cdm-healthFile.js';
	import {
		getMerchants
	} from "@/api/order";
	import {
		mapState
	} from "vuex";
	import Pagination from '@/components/Pagination';
	export default {
		components: {
			Pagination,
		},
		computed: {
			...mapState({
				is_admin: state => state.user.is_admin,
				departDic: (state) => state.dictionary.departDic,
			}),

		},
		data() {
			return {
				keywords: '',
				listQuery: {
					page: '1',
					limit: '10',
					importance: undefined,
					title: undefined,
					type: undefined,
					sort: '+id'
				},
				total: 0,
				id: '',
				allLoading: false,
				loading: false,
				topic: 'topic', //topic-有题目  noTopic-没题目
				editTopic: 'view', //edit-编辑  view-看
				dialogVisible: false,
				postType: 'add', //add=新增 edit-编辑
				active: 1,
				checked: true,
				orderList: [],
				testPaper: {
					title: '',
					desc: '',
					label: ''
				},

				type: '',
				topicList: [{
						name: '单选',
						type: '1'
					},
					{
						name: '多选',
						type: '2'
					},
					{
						name: '图片',
						type: '3'
					},
					{
						name: '简答',
						type: '4'
					},
					{
						name: '填空',
						type: '5'
					},
					{
						name: '单选简答',
						type: '6'
					}
				],
				rules: {
					title: [{
						required: true,
						message: "请输入问卷名称",
						trigger: "blur"
					}, ],
					desc: [{
						required: true,
						message: "请输入问卷简介",
						trigger: "blur"
					}, ],
					agent_id: [{
						required: true,
						message: "请选择机构",
						trigger: "change"
					}, ],
					depart_id: [{
						required: true,
						message: "请选择科室",
						trigger: "change"
					}],
				},
				arr: [],
				questionList: [], //试题
				viewQuestionList: [],
				containerHeight: '',
				innerHeight: '',
			}
		},
		mounted() {
			this.$store.dispatch("dictionary/getDicData", [{
				stateName: "departDic",
				api: "/admin/hospital/getDeparts",
			}, ]);
			if (this.$route.query.id) {
				this.id = this.$route.query.id
				this.postType = this.$route.query.postType
				this.getQuestionInfo()
			}

			this.getQuestionsList()
			this.getorderList()

		},
		updated() {
			// // 对话框定位到顶部
			// let ele = document.getElementById('question');
			// ele.scrollTop = 0;

			// 对话框定位到底部

		},

		methods: {

			getQuestionInfo() {
				this.allLoading = true
				getQuestionInfo(this.id).then(res => {
					if (res.code == 200) {
						this.testPaper = {
							...res.data
						}
						this.viewQuestionList = res.data.options
						this.viewQuestionList.forEach(item => {
							item.isOn = false
						})
					}
					this.allLoading = false
				})
			},
			//提交
			submit() {
				if (!this.viewQuestionList.length) {
					this.$message({
						message: '请添加试题',
						type: 'error'
					})
					return
				}
				this.allLoading = true
				let arr = this.viewQuestionList.map(item => {
					return item.id
				})
				let params = {
					...this.testPaper,
					options_id: arr
				}
				console.log(params)
				if (this.postType == 'add') {
					addTestPaper(params).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '添加成功',
								type: 'success'
							})
							this.$router.go(-1)
						} else {
							this.$message({
								message: res.msg ? res.msg : res.message,
								type: 'error'
							})
						}
						this.allLoading = false
					}).catch(err => {
						this.$message({
							message: err.msg ? err.msg : err.message,
							type: 'error'
						})
						this.allLoading = false
					})
				} else {
					undataQuestion(params, this.id).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '修改成功',
								type: 'success'
							})
							this.$router.go(-1)
						} else {
							this.$message({
								message: res.msg ? res.msg : res.message,
								type: 'error'
							})
						}
						this.allLoading = false
					}).catch(err => {
						this.$message({
							message: err.msg ? err.msg : err.message,
							type: 'error'
						})
						this.allLoading = false
					})
				}

			},
			save() {
				console.log(8888)
				this.viewQuestionList = [...this.arr, ...this.viewQuestionList]
				this.viewQuestionList.forEach(item => {
					item.isOn = false
				})
				console.log(9999, this.viewQuestionList, this.questionList)
				this.questionList.forEach(item1 => {
					if (item1.isOn == true) {
						console.log(item1)
					}
					// item.isOn = false
				})
				this.dialogVisible = false
				this.questionList = []
				this.arr = []
			},
			//移除试题
			removeTopoc() {

				this.viewQuestionList = this.viewQuestionList.filter(item => item.isOn == false)
			},
			//移除change事件
			selectQuestions(status, item, index) {
				this.viewQuestionList[index].isOn = status
				this.$forceUpdate()
			},
			//新增列表
			selectQuestion(status, item, index) {
				if (status) {
					item.isOn = true
					this.$forceUpdate()
					this.arr.push(item)
				} else {
					this.arr.forEach((items, index) => {
						if (item.id == items.id) {
							this.arr.splice(index, 1)
						}
					})
				}
				console.log(this.arr)
			},
			getQuestionsList() {
				this.loading = true
				let arr = []
				let params = {}
				params.page = this.listQuery.page
				params.page_size = this.listQuery.limit
				if (this.viewQuestionList.length) {
					this.viewQuestionList.forEach(item => {
						arr.push(item.id)
					})
					params.ids = arr.toString(arr)
				}
				if (this.type) {
					params.type = this.type
				}
				if (this.keywords.length) {
					params.keywords = this.keywords
				}
				getTestQuestions(params).then(res => {
					if (res.code == '200') {
						let _data = res.data
						this.total = _data.total
						this.listQuery.limit = Number(_data.per_page)
						this.questionList = _data.list
						this.questionList.forEach(item => {
							item.isOn = false
						})
					}
					this.loading = false

				}).catch(err => {
					this.loading = false
				})
			},

			//打开添加试题
			addTopic() {
				this.dialogVisible = true
				this.getQuestionsList()

			},
			//商户字典
			getorderList() {
				console.log(9999999999999)
				getMerchants().then(res => {
					console.log(res, 'res111')
					try {
						if (res.code == '200') {

							this.orderList = res.data
						}
					} catch (e) {
						console.log(e)
					}
				})
			},
			//上一步
			previous() {
				this.active--
			},
			//下一步
			next() {
				console.log("this.testPaper", this.testPaper)
				this.$refs["ruleForm"].validate((valid, obj) => {
					if (valid) {
						this.active++
					} else {
						this.$message.error(Object.values(obj)[0][0]);
					}
				})

			},
			close() {
				this.dialogVisible = false
			},

		}
	}
</script>

<style scoped lang="scss">
	.flex {
		display: flex;
	}

	.justify-center {

		justify-content: center;
	}

	.align-center {
		align-items: center;
	}

	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;

		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			border-radius: 4px;
			margin-right: 8px;
		}
	}

	.zhuyi {
		color: #fcc810;
		text-align: center;
		border-radius: 4px;
	}

	.el-steps {
		width: 50%;
	}

	.select {
		font-size: 13px;
		color: #333333;
		font-weight: bold;

		label {
			line-height: 32px;
		}

		span {
			color: #797a90;
		}
	}

	.step2 {

		.step2-bacc {
			background-color: #f6f6f6;
			display: flex;
			justify-content: center;
			width: 320px;
			height: 190px;
			line-height: 300px;
			align-items: center;
			border-radius: 8px;
			border: 1px solid #e9e9e9;

			i {
				font-size: 50px;
				color: #b0b1b5;
			}


		}

	}

	.mgb-10 {
		margin-bottom: 10px;
	}

	.pat-10 {
		padding-top: 10px;
	}

	.pdb-10 {
		padding-top: 10px;
	}

	.box-card {
		padding: 30rpx;
		margin-top: 20px;
	}

	.topics {
		height: 600px;
		overflow: scroll;
	}

	.question-answer {
		margin-top: 25px;

		.question-answer-img {
			margin-left: 20px;
			width: 160px;
			height: 100px;
			border: 1px dashed #54befc;
			border-radius: 5px;
			background-color: #FAFAFA;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 50px;

			div {
				width: 40px;
				height: 40px;
				background-color: #7D7d7d;
				border-radius: 50%;

				text-align: center;
				line-height: 50px;

			}
		}

		.question-answer-gap {
			display: inline-block;
			margin-left: 20px;

		}

		.question-answer-item {
			display: flex;
			align-items: center;

			div:nth-child(1) {
				margin-left: 20px;
			}

			div {
				width: 14PX;
				height: 14px;
				border-radius: 50%;
				border: 1px solid #DCDFE6;
				margin-right: 10px;
			}

			span {
				display: inline-block;

			}
		}
	}

	.view-span {
		color: #333;
		font-weight: bolder;
		font-size: 14px;
	}

	.textareaStyle {
		width: 100%;
		min-height: 100px;
		border: 1px solid #DCDFE6;
		color: #999999;
		margin-left: 20px;
	}

	.require {
		position: relative;
	}

	.require::before {
		content: '*';
		position: absolute;
		color: red;
		top: 3px;
		left: -10px;
	}
</style>
